import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  IconButton, 
  Container,
  Paper,
  Box,
  Divider,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  Grow,
  Chip
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa los estilos del carrusel
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import './index.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSTransition } from 'react-transition-group';
import './styles.css';

function HomePage() {
  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryRefs, setCategoryRefs] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const toggleFilter = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId) // Deseleccionar si ya está seleccionado
        : [...prev, categoryId] // Agregar si no está seleccionado
    );
  };
  
  const handleColorChange = (colorId) => {
    setSelectedColors((prev) =>
      prev.includes(colorId)
        ? prev.filter((id) => id !== colorId)
        : [...prev, colorId]
    );
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const cacheKey = 'categorias';
        const cacheExpiryKey = `${cacheKey}_expiry`;
        const cachedCategorias = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);
        const now = new Date().getTime();
    
        if (cachedCategorias && cacheExpiry && now < cacheExpiry) {
          console.log("categorias obtenidas del cache");
          const parsedCategorias = JSON.parse(cachedCategorias);
          setCategorias(parsedCategorias);
          categoryRefs.current = parsedCategorias.reduce((acc, value) => {
            acc[value.id] = React.createRef();
            return acc;
          }, {});
        } else {
          console.log("categorias no obtenidas del cache");
          const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/categorias`, { withCredentials: true });
          const categorias = response.data;
          setCategorias(categorias);
          localStorage.setItem(cacheKey, JSON.stringify(categorias));
          localStorage.setItem(cacheExpiryKey, now + 10 * 60 * 1000); // 15 minutos de expiracion
          categoryRefs.current = categorias.reduce((acc, value) => {
            acc[value.id] = React.createRef();
            return acc;
          }, {});
        }
      } catch (error) {
        console.error('Error fetching categorias:', error);
      }
    };

    const fetchProductos = async () => {
      try {
        const cacheKey = `productos_${process.env.REACT_APP_COMMIT_SHA || 'v1'}`;
        console.log("cacheKey: ", cacheKey);
        const cacheExpiryKey = `${cacheKey}_expiry`;
        const cachedProductos = sessionStorage.getItem(cacheKey);
        const cacheExpiry = sessionStorage.getItem(cacheExpiryKey);
        const now = new Date().getTime();

        if (cachedProductos && cacheExpiry && now < cacheExpiry) {
          console.log("productos obtenidos del cache");
          setProductos(JSON.parse(cachedProductos));
          setLoading(false);
        } else {
          console.log("productos no obtenidos del cache");
          const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/productos_completos`, { withCredentials: true });
          const productos = response.data;
          setProductos(productos);
          sessionStorage.setItem(cacheKey, JSON.stringify(productos));
          sessionStorage.setItem(cacheExpiryKey, now + 10 * 60 * 1000); // 15 minutos de expiracion
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching productos:', error);
        setLoading(false);
      }
    };
  

    fetchProductos();
    fetchCategorias();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
        {/* Hero Section */}
      <Paper sx={{ 
        position: 'relative',
        backgroundColor: '#211915',
        color: 'white',
        padding: isMobile ? '2rem 1rem' : '4rem 2rem',
        textAlign: 'center',
        marginBottom: 0,
        marginTop: isMobile ? 14 : 8,
        borderRadius: 0
      }}>
        <Container sx={{ maxWidth: isMobile ? '100%' : '600px' }}>
          <div style={{ marginBottom: '2rem' }}>
            <img src="/oneway_logo_blanco.png" alt="Imagen 1" style={{ width: isMobile ? '200px' : '300px' }} />
          </div>
          <Typography variant="h5" gutterBottom sx={{ fontSize: isMobile ? '1.25rem' : '2rem' }}>
            ROPA URBANA CON UN MENSAJE DE FE
          </Typography>
          <Typography variant="h6" sx={{ maxWidth: '600px', margin: '0 auto', fontSize: isMobile ? '1rem' : '1.25rem' }}>
            Somos diseñadores fabricantes de indumentaria. <br />
            Nos inspira lo trascendente.
          </Typography>
        </Container>
      </Paper>

      {/* Carrusel de imágenes */}
      <Container maxWidth="lg" sx={{ mb: 4, mt: 0, padding: 0 }}>
        <Slider {...settings}>
          <div>
            <img src="/img_header/header1.jpeg" alt="Imagen 1" style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'cover' }} />
          </div>
          <div>
            <img src="/img_header/header2.jpeg" alt="Imagen 2" style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'cover' }} />
          </div>
          <div>
            <img src="/img_header/header3.jpeg" alt="Imagen 3" style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'cover' }} />
          </div>
          <div>
            <img src="/img_header/header4.jpeg" alt="Imagen 4" style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'cover' }} />
          </div>
          <div>
            <img src="/img_header/header5.jpeg" alt="Imagen 5" style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'cover' }} />
          </div>
        </Slider>
      </Container>

      {/* Botón de Filtros */}
      <Box
        sx={{
          position: 'fixed',
          top: isMobile ? 216 : 140,
          left: 16,
          zIndex: 1000,
          transition: 'all 0.3s ease-in-out',
          width: filterOpen ? 300 : 56,
          height: filterOpen ? 300 : 56,
          borderRadius: filterOpen ? 8 : '50%',
          boxShadow: filterOpen ? 3 : 'none',
          backgroundColor: 'white',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          onClick={toggleFilter}
          sx={{
            width: '100%',
            height: '100%',
            display: filterOpen ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <FilterListIcon />
        </IconButton>
        {filterOpen && (
          <Box sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column'}}>
            <IconButton
              onClick={toggleFilter}
              sx={{
                alignSelf: 'flex-end',
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
                position: 'absolute',
                top: 8,
                left: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Filtrar por Categoría</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                  {categorias.map((categoria) => (
                    <Chip
                      key={categoria.id}
                      label={categoria.nombre}
                      onClick={() => handleCategoryChange(categoria.id)}
                      sx={{
                        minWidth: 60,
                        cursor: 'pointer',
                        backgroundColor: selectedCategories.includes(categoria.id) ? theme.palette.primary.main : 'default',
                        color: selectedCategories.includes(categoria.id) ? '#fff' : 'default',
                        '&:hover': {
                          backgroundColor: isMobile ? 'default' : theme.palette.secondary.main,
                        },
                        '@media (hover: none)': {
                          '&:hover': {
                            backgroundColor: selectedCategories.includes(categoria.id) ? theme.palette.primary.main : 'default',
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Filtrar por Color</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                  {[...new Map(productos.flatMap((producto) => producto.colores).map(color => [color.id, color])).values()].map(
                    (color) => (
                      <Chip
                        key={color.id}
                        label={color.nombre}
                        onClick={() => handleColorChange(color.id)}
                        sx={{
                          minWidth: 60,
                          cursor: 'pointer',
                          whiteSpace: 'normal',
                          textAlign: 'center',
                          backgroundColor: selectedColors.includes(color.id) ? theme.palette.primary.main : 'default',
                          color: selectedColors.includes(color.id) ? '#fff' : 'default',
                          '&:hover': {
                            backgroundColor: isMobile ? 'default' : theme.palette.secondary.main,
                          },
                          '@media (hover: none)': {
                            '&:hover': {
                              backgroundColor: selectedColors.includes(color.id) ? theme.palette.primary.main : 'default',
                            },
                          },
                        }}
                      />
                    )
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

        
        {/* Products Section */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {categorias
            .filter((categoria) => selectedCategories.length === 0 || selectedCategories.includes(categoria.id))
            .map((categoria) => (
            <Box key={categoria.id} ref={categoryRefs.current[categoria.id]}>
              <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom 
                sx={{ 
                  textAlign: 'center',
                  mb: 4,
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                {categoria.nombre}
              </Typography>
              <Grid container spacing={4}>
                {productos
                  .filter(producto => producto.categoria_id === categoria.id)
                  .map((producto) => (
                    // Mapear cada color del producto
                    producto.colores
                      .filter((color) => selectedColors.length === 0 || selectedColors.includes(color.id))
                      .map((color, colorIndex) => (
                      <Grid item xs={isMobile ? 6 : 12 } sm={6} md={4} key={`${producto.id}-${colorIndex}`}>
                        <Card sx={{ 
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          transition: '0.3s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: 3
                          },
                        }}>
                          <CSSTransition
                            in={true}
                            appear={true}
                            timeout={300}
                            classNames="fade"
                          >
                            <Link 
                              to={`/producto/${producto.id}?color=${color.id}`} 
                              style={{ textDecoration: 'none' }}
                              state={{ productos }} 
                            >
                              <Box sx={{ 
                                position: 'relative',
                                overflow: 'hidden',
                                height: '100%',
                              }}>
                                <Carousel 
                                  showThumbs={false} 
                                  autoPlay 
                                  infiniteLoop 
                                  showStatus={false}
                                  showArrows={false}
                                  swipeable={false}
                                >
                                  {color.imagenes.map((imagen, index) => (
                                    <div key={index}>
                                      <img 
                                        src={`${process.env.REACT_APP_BACK_URL}${imagen.ruta_imagen}`} 
                                        alt={`${producto.nombre} - ${color.nombre} ${index + 1}`}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover'
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Carousel>
                              </Box>
                            </Link>
                          </CSSTransition>
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography 
                              variant="h6" 
                              gutterBottom 
                              component={Link} 
                              to={`/producto/${producto.id}?color=${color.id}`} 
                              sx={{
                                textDecoration: 'none',
                                color: 'inherit',
                                fontWeight: 600,
                              }}
                              state={{ productos }} 
                            >
                              {producto.nombre} {color.nombre}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" paragraph>
                              {producto.descripcion}
                            </Typography>
                            {producto.precio_descuento ? (
                              <>
                                <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                                  ${producto.precio}
                                </Typography>
                                <Typography variant="h6" color="text.primary" display="inline">
                                  ${producto.precio_descuento}
                                </Typography>
                                <Typography variant="body2" color="success.main" display="inline" sx={{ marginLeft: 1 }}>
                                  {Math.round(((producto.precio - producto.precio_descuento) / producto.precio) * 100)}% OFF
                                </Typography>
                                <Box mt={1}>
                                  <Typography variant="body" color="alert.main" display="inline">
                                  <span style={{ fontWeight: 'bold' }}>
                                    ${(producto.precio_descuento * 0.91).toFixed(2)} 
                                    </span> con Transferencia
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                <>
                                  <Box mb={1}>
                                    <Typography variant="h6" color="text.primary" display="inline">
                                      ${producto.precio}
                                    </Typography>
                                  </Box>

                                  <Typography variant="body" color="alert.main" display="inline">
                                    <span style={{ fontWeight: 'bold' }}>
                                      ${(producto.precio * 0.91).toFixed(2)}
                                    </span> con Transferencia
                                  </Typography>
                                </>
                              </>
                              
                              
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ))}
              </Grid>
              <Divider sx={{ my: 4 }} />
            </Box>
          ))}
        </Container>

        {/* Footer */}
        <Box component="footer" sx={{ 
        backgroundColor: '#211915',
        color: 'white',
        py: 6,
        mt: 'auto'
        }}>
        <Container maxWidth="lg">
            <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                Sobre One Way
                </Typography>
                <Typography variant="body2">
                Somos una marca de ropa urbana comprometida con la calidad y el estilo. 
                Nuestra misión es ayudarte a expresar tu personalidad a través de la moda.
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                Contacto
                </Typography>
                <a href="https://api.whatsapp.com/send/?phone=5492235993095&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <IconButton
                        color="inherit"
                        sx={{
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        }}
                    >
                        <WhatsAppIcon />
                        <Typography variant="body2" marginLeft={1}>
                          +54 9 2235 99-3095
                        </Typography>
                    </IconButton>
                    </a>
                <Typography variant="body2" marginTop={1}>
                Dirección: Cabildo 225, Turdera
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                    Seguinos
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <a href="https://www.instagram.com/oneway.style" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <IconButton
                        color="inherit"
                        sx={{
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        }}
                    >
                        <InstagramIcon />
                        <Typography variant="body2" marginLeft={1}>
                          @oneway.style
                        </Typography>
                    </IconButton>
                    </a>
                </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 4, backgroundColor: 'rgba(255,255,255,0.1)' }} />
            <Typography variant="body2" align="center">
            © {new Date().getFullYear()} One Way. Todos los derechos reservados.
            </Typography>

            
        </Container>
        </Box>
    </>
  );
};

export default HomePage;